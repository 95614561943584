import React, { ReactNode, useEffect } from 'react'
import { useUniqueId } from '@te-digi/styleguide'

import { LinkMock } from '../../components/LinkMock'
import { PlainSelectHTMLExample } from '../../pages/lomakkeet/plainselect'

import ButtonHTMLExample from './ButtonHTMLExample'
import HeaderSearchHTMLExample from './HeaderSearchHTMLExample'
import IconHTMLExample from './IconHTMLExample'
import LogoHTMLExample from './LogoHTMLExample'
import MobileNavHTMLExample from './MobileNavHTMLExample'
import PrimaryNavHTMLExample from './PrimaryNavHTMLExample'
import SecondaryNavHTMLExample from './SecondaryNavHTMLExample'
import { HeaderMobileNavButton } from './HeaderHTMLExample'

type DropdownActionsItemProps = {
  active?: boolean
  children: ReactNode
  icon?: string
}

const DropdownActionsItem = ({
  active,
  children,
  icon
}: DropdownActionsItemProps) => (
  <li>
    <LinkMock className={`dropdown-actions-item ${active ? 'active' : ''}`}>
      {icon ? (
        <IconHTMLExample
          className="dropdown-actions-item-icon-left"
          name={icon}
          size="lg"
        />
      ) : (
        <span className="pl-xl" />
      )}
      <span className="dropdown-actions-item-label">{children}</span>
    </LinkMock>
  </li>
)

const HeaderUserHTMLExample = ({
  activePrimaryItem = 'Henkilöasiakkaat',
  activeSecondaryItem = 'Työnhaku'
}: {
  activePrimaryItem?: string
  activeSecondaryItem?: string
} = {}) => {
  const mobileNavId = useUniqueId('mobile-nav')

  useEffect(() => {
    // @ts-expect-error
    window.StyleGuide.initializeToggle()
    // @ts-expect-error
    window.StyleGuide.initializeUserMenu()
  }, [])

  return (
    <div className="page-header-background">
      <header className="page-header">
        <HeaderMobileNavButton id={mobileNavId} />
        <div className="page-header-logo-container">
          <LinkMock>
            <LogoHTMLExample />
          </LinkMock>
        </div>
        <div className="page-header-search">{HeaderSearchHTMLExample()}</div>
        <div className="page-header-user">
          <div className="dropdown page-header-user-dropdown">
            <button
              aria-controls="user-menu"
              aria-expanded="false"
              aria-haspopup="true"
              className="btn btn-secondary page-header-user-button"
              data-toggle="dropdown"
              id="user-menu-button"
              type="button"
            >
              <span className="btn-content page-header-user-button-content">
                <span className="btn-label btn-label-with-sublabel">
                  Matti
                  <br />
                  <span className="btn-sublabel">Matin Nakkikioski</span>
                </span>
                <IconHTMLExample
                  className="btn-icon-right dropdown-toggle-icon-expand"
                  name="ExpandIcon"
                  size="lg"
                />
                <IconHTMLExample
                  className="btn-icon-right dropdown-toggle-icon-collapse"
                  name="CollapseIcon"
                  size="lg"
                />
              </span>
              <span className="page-header-button page-header-user-button-mobile">
                <span className="page-header-button-content">
                  <span className="page-header-button-icon">
                    <IconHTMLExample
                      name="UserIcon"
                      size="lg"
                    />
                  </span>
                  <span className="page-header-button-label">Matti</span>
                </span>
              </span>
            </button>
            <div
              aria-labelledby="user-menu-button"
              className="dropdown-menu dropdown-menu-right page-header-user-menu"
              id="user-menu"
            >
              <div className="dropdown-section">
                <p className="lead mb-0">Matti Meikäläinen</p>
                <p className="paragraph-light mb-0">Matin Nakkikioski</p>
              </div>
              <div className="dropdown-divider" />
              <ul className="dropdown-actions">
                <DropdownActionsItem icon="HomeIcon">
                  Etusivu
                </DropdownActionsItem>
                <DropdownActionsItem
                  active
                  icon="RibbonIcon"
                >
                  Osaamisprofiili
                </DropdownActionsItem>
                <DropdownActionsItem icon="BriefcaseIcon">
                  Työpaikat
                </DropdownActionsItem>
                <DropdownActionsItem icon="SearchIcon">
                  Oma työnhaku
                </DropdownActionsItem>
                <DropdownActionsItem icon="ProfileIcon">
                  Omat tiedot
                </DropdownActionsItem>
              </ul>
              <div className="dropdown-divider" />
              <div className="dropdown-section pb-0">
                <p
                  className="lead mb-0"
                  id="header-user-html-example-roles-label"
                >
                  Vaihda käyttäjäroolia
                </p>
              </div>
              <ul
                aria-labelledby="header-user-html-example-roles-label"
                className="dropdown-actions"
              >
                <DropdownActionsItem
                  active
                  icon="CheckIcon"
                >
                  Henkilöasiakas
                </DropdownActionsItem>
                <DropdownActionsItem>
                  Matin Nakkikioski
                  <div className="text-sm text-line-height-xs">12345678-9</div>
                </DropdownActionsItem>
                <DropdownActionsItem>
                  Antin Autopesula
                  <div className="text-sm text-line-height-xs">12345678-0</div>
                </DropdownActionsItem>
              </ul>
              <div className="dropdown-divider" />
              <div className="dropdown-section">
                <div className="buttons buttons-center mb-0">
                  <ButtonHTMLExample>Kirjaudu ulos</ButtonHTMLExample>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page-header-language">{PlainSelectHTMLExample()}</div>
      </header>
      {PrimaryNavHTMLExample(activePrimaryItem, activeSecondaryItem)}
      {SecondaryNavHTMLExample(activeSecondaryItem, activePrimaryItem)}
      <MobileNavHTMLExample id={mobileNavId} />
    </div>
  )
}

export { HeaderUserHTMLExample }
