import React from 'react'

import {
  Button,
  Dropdown,
  DropdownSection,
  Inline,
  Paragraph,
  Stack
} from '@te-digi/styleguide'

const PLACEMENT_GROUPS = [
  ['top-start', 'top', 'top-end'],
  ['bottom-start', 'bottom', 'bottom-end']
] as const

const DropdownPlacementExample = () => (
  <Stack gap="md">
    {PLACEMENT_GROUPS.map((placementGroup, index) => (
      <Inline
        gap="md"
        key={index}
      >
        {placementGroup.map(placement => (
          <Dropdown
            key={placement}
            placement={placement}
            trigger={
              <Button>
                {placement} {placement === 'bottom-start' && '(oletus)'}
              </Button>
            }
          >
            <DropdownSection>
              <Paragraph noMargin>Lorem ipsum dolor sit amet</Paragraph>
            </DropdownSection>
          </Dropdown>
        ))}
      </Inline>
    ))}
  </Stack>
)

export { DropdownPlacementExample }
