import React from 'react'

import {
  Dropdown,
  DropdownActions,
  DropdownActionsItem,
  DropdownDivider,
  DropdownSection,
  List,
  ListItem,
  Paragraph
} from '@te-digi/styleguide'

import { Code } from '../../components/Code'
import { Playground } from '../../components/Playground'
import { Content } from '../../components/Content'
import { DropdownExample } from '../../examples/react/DropdownExample'
import { DropdownPlacementExample } from '../../examples/react/DropdownPlacementExample'
import { Section } from '../../components/Section'
import { HeaderUserHTMLExample } from '../../examples/html/HeaderUserHTMLExample'

// HUOM! lead-teksti määritellään pages.ts -tiedostossa
const Page = () => (
  <Content
    heading="Dropdown"
    components={[
      { component: Dropdown },
      { component: DropdownDivider },
      { component: DropdownSection },
      { component: DropdownActions },
      { component: DropdownActionsItem, restElement: true }
    ]}
    status={[{ type: 'accessible', version: '16.3.0' }]}
  >
    <Section>
      <Paragraph>
        <Code>Dropdown</Code>-komponentista on kolme eri leveyttä (
        <Code>size</Code>):
      </Paragraph>
      <List variant="unordered">
        <ListItem>
          200px (<Code>sm</Code>)
        </ListItem>
        <ListItem>
          280px (<Code>md</Code>, oletus)
        </ListItem>
        <ListItem>
          500px (<Code>lg</Code>, näytetään mobiilinäkymässä <Code>md</Code>
          -koossa)
        </ListItem>
      </List>
    </Section>
    <Section title="Esimerkkejä">
      <Playground
        enableOverflow
        example={DropdownExample}
      />
      <Playground
        enableOverflow
        example={DropdownPlacementExample}
      />
      <Paragraph>
        Verkkopalvelun puolella <Code>Dropdown</Code>-komponenttia on käytetty
        lähinnä <Code>Header</Code>-komponentista löytyvässä käyttäjävalikossa.
      </Paragraph>
      <Playground
        enableOverflow
        example={HeaderUserHTMLExample}
        format="html"
        widePreview
      />
    </Section>
  </Content>
)

export default Page
